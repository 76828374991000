import React  from 'react'
import Sketch from "react-p5";

function Ctxt() {
  let pressed = false;
  onmousedown = (event) => {
    // console.log("mouse down");
    pressed = true
 }

 onmouseup = (event) => {
    pressed = false ;
  //  console.log("mouse up")
 }
  const touchdown =((e)=>{
    // console.log("touchdown")
    pressed = true
  })

  const touchup = ((e)=>{
    // console.log("touchup")
    pressed = false ;
  })





  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  class twistling{
    constructor(p5,imgpath,owl){
      this.alive = true
      this.x = window.innerWidth/2;
      this.y = window.innerHeight/2;
      this.xspeed = getRandomArbitrary(-6, 6);
      this.yspeed = getRandomArbitrary(-3, 3);

      if(owl){
        this.pop = p5.loadImage("https://cloudflare-ipfs.com/ipfs/QmQ7TVhsoEKB3YYMUjcVxm7SCzgHRgFaesGtL68eLBeAT3", (img)=>{
          this.popscaley = img.height
          this.popscalex = img.width })
      }else{
        this.pop = p5.loadImage("https://cloudflare-ipfs.com/ipfs/QmQ7TVhsoEKB3YYMUjcVxm7SCzgHRgFaesGtL68eLBeAT3", (img)=>{
          this.popscaley = img.height / 3
          this.popscalex = img.width / 3 })
      }
      this.popframe = 0;
      this.maxframe = 5;
      this.counter = 0;
      this.exploded = false
      this.img = p5.loadImage(imgpath, (img)=>{
        if(owl){
          this.scaley = img.height / 1.5
          this.scalex = img.width / 1.5
        }
        else
        {
        this.scaley = img.height
        this.scalex = img.width

      }
      })
    }

    move(p5,pressed){
      this.x += this.xspeed;
      this.y += this.yspeed;
      // test if click on agent, if yes, splat.
      if(pressed && this.alive){
        if(this.clickbox(p5) && pressed){
          this.splat()
        }
      }


      if(!pressed){
        if (this.x > window.innerWidth || this.x<0) {
          this.xspeed = -this.xspeed;
        }
        if (this.y < 0 || this.y > window.innerHeight) {
          this.yspeed = -this.yspeed;
        }
      }
    }

    splat(){
      this.alive = false
      this.img = this.pop;
      this.scalex = this.popscalex
      this.scaley = this.popscaley
    }

    clickbox(p5){
      //console.log(this.x,this.y,this.scalex,this.scaley)
      //console.log(p5.mouseX >= this.x - this.scalex && p5.mouseX <= this.x + this.scalex && p5.mouseY >= this.y - this.scaley && p5.mouseY <= this.y + this.scaley)
      if
      (pressed && p5.mouseX >= this.x - this.scalex && p5.mouseX <= this.x + this.scalex && p5.mouseY >= this.y - this.scaley && p5.mouseY <= this.y + this.scaley)
      {
        return true
      }
      else
      {
        return false
      }
    }

    display(p5){

      p5.push()
      p5.translate(this.x,this.y)
      p5.rotate(this.x / 5000 * 45)
      if(!this.alive){

        let timer = Math.floor(this.counter/10)
        if(timer >= this.maxframe){
          this.exploded = true;
        } else {
          this.counter += 1
          this.img.setFrame(timer)
        }
      }

      if(!this.alive){
        p5.image(this.img, -this.scalex/5, -this.scaley/5,this.scalex/2.5, this.scaley/2.5); 
      }else
      {
        p5.image(this.img, -this.scalex/2, -this.scaley/2,this.scalex, this.scaley);
      }
      p5.pop();
    }
  }

  let num = 12;
  let agents = [];
  let frames = 0;

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(window.innerWidth, window.innerHeight).parent(canvasParentRef)

    let temp;
    let offset = getRandomArbitrary(2,66)

    for (let i=0;i<num;i++){
      // console.log(2 + ((Math.floor(offset) + i) % 66), "should be between 2 and 66")
      let index = (2 + ((Math.floor(offset) + i) % 66));
      agents[i] = new twistling(p5,"https://arweave.net/b5QjgT9Q6MQsCTCxw24WkZeZvmP5_0nKzYQAAWYDNYk/" +index+ ".png", false)//, lifespan);//images[i]);
      temp = i
    }

    agents[temp+1] = new twistling(p5,"https://arweave.net/L6pqH8A8ArK-qKgEkxxyLwLO7_sRz_k7M5q7jxd8JD4", true) //lifespan)

  }
  
  const draw = p5 => {
    p5.clear()
    // console.log(pressed)
    for (let i=0;i<num+1;i++){
      if(frames>250 && agents[i] !== undefined){
      agents[i].move(p5,pressed)
      agents[i].display(p5)
    }

    if(agents[i] !== undefined && agents[i].exploded === true){
      delete agents[i];
      // console.log("agent "+ i + " has been popped")
    }

    
    }


    frames++;
  }
  

  return <Sketch touchStarted={touchdown} touchEnded={touchup} className={"sketch"} setup={setup} draw={draw} />
}

export default Ctxt