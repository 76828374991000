import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import React from 'react'
import { useBalance } from './Hooks';



function ConnectBar({smol}) {

    const { account, active, activate, chainId } = useWeb3React();
    const balance = useBalance();


    return (  <>
        <nav className="bg-transparent">
          <div className={active ? " mx-auto  px-5":" mx-auto" }>
            <div className={active ? "justify-between relative flex items-center h-16 text-gray-100":"justify-center relative flex items-center h-16 text-gray-100"}>
              {active ? (
                <>
                  <div>
                    {chainId === 1 ? "Mainnet" : "Testnet"}
                  </div>
                 { !smol ? 
                 <div className="center">
                 {account}
    
                  </div>
                  :
                  <div className="center">
                    {account.substr(0, 5)}...{account.substr(-3, 8)}
                  </div>
                  }
                  <div>{balance}</div>
     
                </>
              ) : (
                <>
                  <button
                    className={ active ? "  ":" w-screen h-20"} //" h-10 w-screen border-gray-400 rounded-md self-center"
                    onClick={() => {
                      activate(new InjectedConnector({}));
                      console.log("connected")
                    }}
                    
                  >
                    CONNECT
                  </button>

                </>
              )}
            </div>
    
          </div>
          
        </nav>
    </>
      );
}

export default ConnectBar