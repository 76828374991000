import './App.css';
import './custom.css';
import React from 'react'
import Ctxt from './functionComponents/Ctxt'
import Mint from "./functionComponents/Mint";
import ConnectBar from "./functionComponents/ConnectBar";
// import ContractAddress from "./functionComponents/ContractAddress"
import { useWindowSize } from "./functionComponents/Hooks";
import contractDetails, { contractAddress } from './contractDetails'
import ContractAddressBar from './functionComponents/ContractAddressBar';


function App() {

  const smol = useWindowSize().width < 600;


  return <>
    <ConnectBar smol={smol} />
    {/* <div className='ctrdaddy'>
      <div onClick={linkContract} className = "ctraddr h-auto">CONTRACT</div>
    </div> */}
    <ContractAddressBar addr = {contractAddress}/>
    {/* setConnected = {setConnected} */}
    {/* <div>{connected}</div> */}
    <Mint contractObj= {contractDetails} />
    {/* <Test contractObj = {contractDetails} /> */}
    {/* <ContractAddress addr = {contractDetails.contractAddress} /> */}

    <Ctxt />

    </> //https://rinkeby.etherscan.io/address/" + contractAddress 
}

export default App