import React, {useState} from 'react'
import {ethers} from 'ethers'
import { useWeb3React } from '@web3-react/core';
import ReactAudioPlayer from 'react-audio-player';

function Mint({contractObj}) {
  const {contractAddress:address, abi} = contractObj
  const { active, library: provider } = useWeb3React();

  const [mintedout, setmintedout] = useState(false);

  testMintedOut()

  async function mint(){
    if (active){
    const signer = provider.getSigner()
    const contract = new ethers.Contract(address, abi, signer);
    try {
      // const options = {value: ethers.utils.parseEther("0.01")}
      await contract.mint(); //1, options);
    } catch (error) {
      console.log(error);
    }
    } else {
      console.log("Something's broken")
    }
  }

  async function testMintedOut(){
    if (active){
      // const signer = provider.getSigner()
      // const contract = new ethers.Contract(address, abi, signer);
      const contract = new ethers.Contract(address, abi, provider);
    try {
      let soldout = await contract.mintedout(); //1, options);
      setmintedout(soldout)
    } catch (error) {
      console.log(error);
    }
    } else {
      console.log("Something's broken")
    }
  }

  
  return (
    <div className="flex justify-around w-full h-full p-5">
      {!(active && !mintedout) ?
            <>
            <div className='mintbtn spesh'>
              {mintedout?<>MINTED OUT</>:<></>}
                <ReactAudioPlayer repeat src = {'https://arweave.net/r2_0iorn7BRBmsMK0UndmNE2vtssn-XxtTmn7OSRhSU'} autoPlay = {true}/>
            </div>
          </>
      :
      <>
       <div className='mintbtn'>
          <button onClick={() => mint()} ><img className = 'mint' src="https://cdn.discordapp.com/attachments/912314291066118164/1008440025857405109/Untitled_Artwork.gif" alt="sparkling cotton candy mint button" /></button>
          <ReactAudioPlayer repeat src = {'https://arweave.net/r2_0iorn7BRBmsMK0UndmNE2vtssn-XxtTmn7OSRhSU'} autoPlay = {true}/>
        </div>
      </>
      }
    </div>
  )
}

export default Mint