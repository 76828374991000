import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { formatEther } from "@ethersproject/units";

function useBalance() {
    const { account, library } = useWeb3React();
    const [balance, setBalance] = useState();
  
    useEffect(() => {
      if (account) {
        library.getBalance(account).then((val) => setBalance(val));
      }
    }, [account, library]);
  
    return balance ? `${formatEther(balance).substring(0,5)} Ξ` : null;
  }
  
function useBlockNumber() {
const { library } = useWeb3React();
const [blockNumber, setBlockNumber] = useState();

useEffect(() => {
    if (library) {
    const updateBlockNumber = (val) => setBlockNumber(val);
    library.on("block", updateBlockNumber);

    return () => {
        library.removeEventListener("block", updateBlockNumber);
    };
    }
}, [library]);

return blockNumber;
}
  
// Hook
function useWindowSize() {
// Initialize state with undefined width/height so server and client renders match
// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
});
useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
    // Set window width/height to state
    setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
}, []); // Empty array ensures that effect is only run on mount
return windowSize;
}

export {useBalance, useBlockNumber, useWindowSize}