import React from 'react'
import { useWeb3React } from '@web3-react/core';


function ContractAddressBar({addr}) {
  const { chainId } = useWeb3React();

  function linkContract(){
    console.log(chainId)
    if(chainId === 4){
    window.open("https://rinkeby.etherscan.io/address/" + addr , "_blank")
    }else{
     window.open("https://etherscan.io/address/" + addr , "_blank")
    }
  }
  return (
    <div className='ctrdaddy'>
      <div onClick={linkContract} className = "ctraddr h-auto">CONTRACT</div>
    </div>
  )
}

export default ContractAddressBar